export const resetState = () => ({
  accounts: {
    data: [],
    meta: {
      total: 0,
    }
  },
  filters: {
    page: 1,
  },
  error: null,
  loading: false
})

export default resetState()
