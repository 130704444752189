import modules from "./modules";
import { insignaServices } from "../../../../services";

export default {
  namespaced: true,
  modules,
  actions: {
    getConsultors: async ({ commit }) => {
      try {
        commit("setLoading", true);
        commit("setError", null);

        const consultor = await insignaServices.getConsultor();

        commit("setConsultors", consultor.data);
      } catch (error) {
        commit("setError", error);
      } finally {
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setConsultors(state, payload) {
      state.consultors = payload;
    },
  },
  getters: {},
  state: {
    loading: false,
    error: null,
    consultors: [],
  },
};
