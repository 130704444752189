import axios from "axios";
import qs from "query-string";
import { createInstance } from "../config";
import erros from "./errors";

const solicitationAccountApi = createInstance(
  "/v1/admin/solicitation-accounts"
);
const internalSolicitationAccountApi = createInstance(
  "/v1/internal/solicitation-account"
);

const clearEmptyFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

function parseFilter({
  itemsPerPage,
  feedback,
  analyze,
  recruitment,
  active,
  ...filters
}) {
  return clearEmptyFromObject({
    ...filters,
    per_page: itemsPerPage,
    feedback: feedback ? 1 : null,
    analyze: analyze ? 1 : null,
    recruitment: recruitment ? 1 : null,
    active: active ? null : 1,
  });
}

export async function searchSolicitationAccounts(filters) {
  try {
    const { data } = await solicitationAccountApi.get("", {
      params: parseFilter(filters),
      paramsSerializer: qs.stringify,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function deleteSolicitationAccount(solicitationAccountIds) {
  try {
    await solicitationAccountApi.delete("", {
      params: { solicitation_account_id: solicitationAccountIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "bracket" });
      },
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function manualApproval({
  solicitation_account_id,
  users,
  send_notification = null,
  test_version_id = null,
  refused_user_observation = null,
}) {
  try {
    await internalSolicitationAccountApi.post("/approval", {
      solicitation_account_id,
      send_notification,
      test_version_id,
      refused_user_observation,
      users: {
        id: users.map((user) => user.id),
        start_at: users.map((user) => user.start_at),
        skill_level_classification_id: users.map(
          (user) => +user.skill_level_classification_id
        ),
        skill_level_id: users.map((user) => +user.skill_level_id),
      },
    });
  } catch (error) {
    const { code, message } = error.response.data;
    const randomizationFailed =
      "Randomization failed for not having items to be drawn.";

    if (code === "500" && message === randomizationFailed) {
      throw erros.RANDOMIZATION_FAILED;
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getSkillLevelDetails(solicitation_account_id) {
  try {
    const { data } = await solicitationAccountApi.get(
      `/${solicitation_account_id}/skill-levels`
    );

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getTestVersions(
  solicitation_account_id,
  skill_level_detail_id
) {
  try {
    const { data } = await internalSolicitationAccountApi.get(
      `/${solicitation_account_id}/versions`,
      {
        params: clearEmptyFromObject({
          skill_level_detail_id: skill_level_detail_id ?? null
        }),
        paramsSerializer: qs.stringify,
      }
    );

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function sendLoginEmailSolicitationAccount({
  solicitationAccountId,
  solicitationIds,
  notifies,
}) {
  try {
    await solicitationAccountApi.post("user-login/notify", {
      solicitation_account_id: solicitationAccountId,
      solicitation_id: solicitationIds,
      notifies: notifies ? 1 : 0,
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response.status === 422) {
      throw new Error(
        `${error.response.data.message} \n
        ${JSON.stringify(error.response.data.errors)}`
      );
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function userRefusedApprovalSolicitationAccount({
  solicitationIds,
  userIds,
}) {
  try {
    await solicitationAccountApi.put("approval-user-refused", {
      params: { solicitation_id: solicitationIds, user_id: userIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "bracket" });
      },
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response.status === 422) {
      throw new Error(
        `${error.response.data.message} \n
        ${JSON.stringify(error.response.data.errors)}`
      );
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}
