import { mutation, actions } from "./types";
import { insignaServices } from "../../../../services";

export default {
  [actions.GET_FEEDBACKS]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);

      const feedbacks = await insignaServices.getFeedbacks(filters);

      commit(mutation.SET_FEEDBACKS, feedbacks);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },

  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
