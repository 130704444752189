import { insignaServices } from "../../../services";
import { mutation, actions } from "./types";

export default {
  [actions.LOGIN]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_IS_AUTHENTICATED, false);

      await insignaServices.login(payload);

      commit(mutation.SET_IS_AUTHENTICATED, true);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.LOGOUT]: ({ commit }) => {
    commit(mutation.SET_USER_LOGGED, {});
    commit(mutation.SET_IS_AUTHENTICATED, false);

    insignaServices.logout();
  },
  [actions.GET_USER_INFO]: async ({ commit }) => {
    try {
      commit(mutation.SET_LOADING, true);

      const data = await insignaServices.me();

      commit(mutation.SET_USER_LOGGED, data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_NOTIFICATIONS]: async ({ commit }) => {
    try {
      commit(mutation.SET_LOADING, true);
      // const oldNotifications = [
      //   ...new Set(state.notifications.map((notification) => notification.id)),
      // ];

      const { data } = await insignaServices.notifications();

      // const newNotifications = [
      //   ...new Set(data.map((notification) => notification.id)),
      // ];

      // // validate if the notification has new one and if true, then console.log it
      // newNotifications.forEach((notification) => {
      //   if (!oldNotifications.includes(notification)) {
      //     console.log(`New notification: ${notification}`);
      //   }
      // });
      // console.log(data);
      commit(mutation.SET_NOTIFICATIONS, data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SETTINGS]: async ({ commit }, payload) => {
    commit(mutation.SET_SETTINGS, payload);
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
