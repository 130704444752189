import { insignaServices } from "../../../services";
import { mutation, actions } from "./types";

export default {
  [actions.GET_NATURES]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);

      const natures = await insignaServices.getNatures(filters);

      commit(mutation.SET_NATURES, natures.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_TEST_TYPES]: async ({ commit }, natureId, filters) => {
    try {
      commit(mutation.SET_LOADING, true);

      const testTypes = await insignaServices.getTestTypes(natureId, filters);

      commit(mutation.SET_TEST_TYPES, testTypes.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
