<template>
  <v-alert
    v-model="alert"
    type="error"
    transition="scale-transition"
    elevation="2"
    class="mt-5"
    dismissible
  >
    {{ msg }}
    <slot />
  </v-alert>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      default: undefined,
    },
    timer: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    timeOn: false,
  }),
  computed: {
    msg() {
      const error = this.getError();

      return error;
    },
    alert: {
      get() {
        const error = this.getError();

        return !!error;
      },
      set() {
        this.resetError();
      },
    },
  },
  methods: {
    getContext() {
      return this.$store._modulesNamespaceMap[`${this.store}/`].context;
    },
    resetError() {
      const context = this.getContext();
      context.commit("setError", null);
    },
    getError() {
      const context = this.getContext();
      const { error } = context.state;

      if (error) this.startTime();

      return error;
    },
    startTime() {
      if (!this.timeOn) {
        this.timeOn = true;

        if (this.timer) {
          setTimeout(() => {
            this.resetError();
            this.timeOn = false;
          }, 5500);
        }
      }
    },
  },
};
</script>
