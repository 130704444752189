import { insignaServices } from "../../../../services";
import { mutation, actions } from "./types";

export default {
  [actions.GET_ACCOUNTS]: async ({ commit }) => {
    try {
      commit(mutation.SET_LOADING, true);

      const accounts = await insignaServices.getAccounts();

      commit(mutation.SET_ACCOUNTS, accounts.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
