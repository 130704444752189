export const getters = {
  ROLES_DICIONARY: 'rolesDicionary',
  USER_ADMIN: 'userAdmin',
};

export const mutation = {
  SET_FILTERS: "setFilters",
  SET_USERS: "setUsers",
  SET_USER: "setUser",
  SET_MAGIC_LINK: "setMagicLink",
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  GET_USERS: "getUsers",
  GET_USER: "getUser",
  CREATE_USER: "createUser",
  UPDATE_USER: "updateUser",
  DELETE_USER: "deleteUser",
  GENERATE_MAGIC_LINK: "generateMagicLink",
  RESET_PASSWORD: "resetPassword",
  EXPORT_USERS: "exportUsers",
  IMPORT_USERS: "importUsers",

  RESET: "reset",
};
