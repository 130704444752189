import axios from "axios";
import qs from "query-string";
import erros from "./errors";
import { createInstance } from "../config";

const solicitationApi = createInstance("/v1/admin/solicitations");

const clearEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

const sanitizeErrorImport = (errors) =>
  errors
    .map((error) => error.map((field) => `<p>• ${field}<p>`).join(""))
    .join("\n");

function filterParse({
  itemsPerPage,
  feedback,
  analyze,
  recruitment,
  delivery_result,
  feedback_pending,
  active,
  solicitation_expired,
  has_cheat,
  speak_rule_applied,
  sortBy,
  sortDesc,
  ...filters
}) {
  const mapSort = {
    responsible: "responsible_id",
    "user.account_name": "account_id",
    "user.account_id": "account_id",
  };

  return clearEmpty({
    ...filters,
    per_page: itemsPerPage,
    feedback: feedback ? 1 : null,
    analyze: analyze ? 1 : null,
    recruitment: recruitment ? 1 : null,
    delivery_result: delivery_result ? 1 : null,
    feedback_pending: feedback_pending ? 1 : null,
    active: active ? null : 1,
    solicitation_expired: solicitation_expired ? 1 : null,
    has_cheat: has_cheat ? 1 : null,
    speak_rule_applied: speak_rule_applied ? 1 : null,
    sort_by: sortBy.map((item) => {
      const key = mapSort[item] || item;

      return key.replaceAll(".", "_");
    }),
    sort_desc: sortDesc.map((item) => (item ? 1 : 0)),
  });
}

export async function searchSolicitations(filters) {
  try {
    const { data } = await solicitationApi.get("", {
      params: filterParse(filters),
      paramsSerializer: (query) =>
        qs.stringify(query, { arrayFormat: "bracket" }),
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getNotifications(soliciationId) {
  try {
    const { data } = await solicitationApi.get(
      `/${soliciationId}/notifications`
    );

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function exportSolicitations(filters) {
  try {
    const { data } = await solicitationApi.get("/export", {
      params: filterParse(filters),
      paramsSerializer: (query) =>
        qs.stringify(query, { arrayFormat: "bracket" }),
      responseType: "blob",
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function deliveryResult(payload) {
  try {
    await solicitationApi.post(`/result-delivery`, payload);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response.status === 422) {
      throw new Error(
        `${error.response.data.message} \n
        ${JSON.stringify(error.response.data.errors)}`
      );
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function resetAttemps(solicitationIds) {
  try {
    await solicitationApi.put("/reset-attempts", {
      solicitation_id: solicitationIds,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function addCheat(solicitationId, cheat, comment) {
  try {
    await solicitationApi.put("/cheat", {
      comment,
      cheat,
      solicitation_id: solicitationId,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function addComment(solicitationId, comment) {
  try {
    await solicitationApi.post("/comment", {
      comment,
      solicitation_id: solicitationId,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function removeComment(commentId) {
  try {
    await solicitationApi.delete(`/comment/${commentId}`);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function deleteSolicitation(solicitationIds) {
  try {
    await solicitationApi.delete("", {
      params: { solicitation_id: solicitationIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "bracket" });
      },
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function revertDeleteSolicitation(solicitationIds) {
  try {
    await solicitationApi.put("revert-delete", {
      solicitation_id: solicitationIds
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateDeadlineSolicitation(
  solicitationIds,
  deadline,
  send_email,
  to,
  cc,
  bcc
) {
  try {
    await solicitationApi.put("/extend-deadline", {
      solicitation_id: solicitationIds,
      deadline,
      send_email,
      to,
      cc,
      bcc,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateDeadlineDeliverySolicitation(
  solicitationIds,
  deadline
) {
  try {
    await solicitationApi.put("/deadline-delivery-date", {
      solicitation_id: solicitationIds,
      deadline,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateResponsible(solicitationIds, userId) {
  try {
    await solicitationApi.post("/responsible", {
      solicitation_id: solicitationIds,
      user_id: userId,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function retifyTestVersionSolicitation(
  solicitationIds,
  testVersion
) {
  try {
    await solicitationApi.put("/retify-test-version", {
      solicitation_id: solicitationIds,
      test_version: testVersion,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function retifySolicitationTestParts(solicitationId, parts) {
  try {
    await solicitationApi.post(`${solicitationId}/execution/retify-parts`, {
      parts,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function retifyGoalPlan(payload) {
  try {
    await solicitationApi.post(`/rectify-goal`, payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function addTestAccount(payload) {
  try {
    await solicitationApi.put("/test-accounts", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function importInvoice(payload, fnCallback) {
  try {
    await solicitationApi.post("/import_invoice", payload, {
      onUploadProgress: (e) => fnCallback(e),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    const { status, data } = error.response;

    if (status === 422) {
      const message = sanitizeErrorImport(data.errors);

      throw new Error(
        `Falha na importação do arquivo de faturamento, \n${message}`
      );
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateResult(solicitationId, payload) {
  try {
    await solicitationApi.put(
      `/${solicitationId}/result-correction`,
      payload
    );
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

// FIXME: corrigir os endpoints abaixo
const assessmentApi = createInstance("/v1/admin/test");

export async function getSolicitationLogs(filters) {
  try {
    const { data } = await assessmentApi.get("/logs", {
      params: filters,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "bracket" });
      },
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getSolicitationByToken(token) {
  try {
    const { data } = await assessmentApi.get(`/${token}`);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getFeedbacks(filters) {
  try {
    const { data } = await assessmentApi.get("/feedbacks", {
      params: filters,
      paramsSerializer: qs.stringify,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}
// FIXME: corrigir os endpoints acima
