import erros from "./errors";
import { createInstance } from "../config";

const scheduleFeedbackAPI = createInstance("/v1/admin/schedule/feedback");

export async function proposeFeedback(payload) {
  try {
    return await scheduleFeedbackAPI.post("/propose", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function scheduleFeedback(payload) {
  try {
    return await scheduleFeedbackAPI.put("", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function doneFeedback(payload) {
  try {
    return await scheduleFeedbackAPI.put("/done", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function canceledFeedback(payload) {
  try {
    return await scheduleFeedbackAPI.put("/canceled", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function finishFeedback(payload) {
  try {
    return await scheduleFeedbackAPI.put("/finished", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}
