import { insignaServices } from "../../../services";
import { mutation, actions } from "./types";
const FileDownload = require("js-file-download");

export default {
  [actions.GET_USERS]: async ({ commit, state }, filters = {}) => {
    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const users = await insignaServices.getUsers({
        ...state.filters,
        ...filters
      });

      commit(mutation.SET_USERS, users);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_USER]: async ({ commit }, userId) => {
    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const user = await insignaServices.getUser(userId);

      commit(mutation.SET_USER, user);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DELETE_USER]: async ({ commit }, userId) => {
    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      await insignaServices.deleteUser(userId);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.CREATE_USER]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const user = await insignaServices.createUser(payload);

      commit(mutation.SET_USER, user);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_USER]: async ({ commit }, { userId, payload }) => {
    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      await insignaServices.updateUser(userId, payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GENERATE_MAGIC_LINK]: async ({ commit }, userId) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_MAGIC_LINK, null);
      commit(mutation.SET_ERROR, null);

      const { data } = await insignaServices.generateMagicLink(userId);

      commit(mutation.SET_MAGIC_LINK, data.magic_link);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RESET_PASSWORD]: async ({ commit }, userId) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.resetPassword(userId);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.EXPORT_USERS]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const { data } = await insignaServices.exportUsers(filters);

      const fileName = `users_${Date.now()}.csv`;

      FileDownload(data, fileName, data.type);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.IMPORT_USERS]: async ({ commit }, file) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const formData = new FormData();
      formData.append(
        "file_to_upload",
        new Blob([file], { type: "text/csv" })
      );

      const cb = (e) => {
        console.log(e);
      };

      await insignaServices.importUsers(formData, cb);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  }
};
