import { resetState } from "./state";
import { mutation } from "./types";

export default {
  [mutation.SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [mutation.SET_ERROR]: (state, payload) => {
    state.error = payload;
  },

  [mutation.SET_SOLICITATION]: (state, payload) => {
    state.solicitation.data = payload;
  },
  [mutation.SET_SOLICITATION_LOG]: (state, payload) => {
    state.solicitation.logs = payload;
  },
  [mutation.SET_SOLICITATION_MAILS]: (state, payload) => {
    state.solicitation.mails = payload;
  },
  [mutation.SET_SOLICITATIONS]: (state, payload) => {
    state.solicitations = payload;
  },
  [mutation.SET_SKILL_LEVELS]: (state, payload) => {
    state.skillLevels = payload;
  },

  [mutation.SET_SOLICITATIONS_HEADERS]: (state, payload) => {
    state.solicitationsHeaders = payload;
  },
  [mutation.SET_SOLICITATIONS_FILTERS]: (state, payload) => {
    state.solicitationsFilters = payload;
  },
  [mutation.SET_SOLICITATIONS_SELECTED]: (state, payload) => {
    state.solicitationsSelected = payload;
  },

  [mutation.RESET]: (state) => {
    const newState = resetState();

    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
