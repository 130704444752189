export const getters = {
};

export const mutation = {
  SET_NATURES: "setNatures",
  SET_TEST_TYPES: "setTestTypes",
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  GET_NATURES: 'getNatures',
  GET_TEST_TYPES: 'getTestTypes',

  RESET: "reset",
};
