import Vue from "vue";
import pt from "vuetify/es5/locale/pt";
import Vuetify from "vuetify/lib/framework";
import minifyTheme from "minify-css-string";
import LRUCache from "lru-cache";

const themeCache = new LRUCache({
  max: 10,
  maxAge: 1000 * 60 * 60,
});

const vuetify = new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        primary: '#37444D',
        text: '#37444D',
        secondary: '#39a3c6',
        accent: '#39a3c6',
        error: '#c13559',
        success: '#b7c72c',
        warning: '#d68500',
        background: '#F9F9F9',
      },
      dark: {
        primary: '#37444D',
        secondary: '#39a3c6',
        accent: '#39a3c6',
        error: '#c13559',
        success: '#b7c72c',
        warning: '#d68500',
      },
      options: { minifyTheme },
    },
    options: { themeCache },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});

Vue.use(Vuetify);

export default vuetify;
