import { insignaServices } from "../../../../services";
import { actions, mutation } from "./types";

export default {
  [actions.GET_APPOINTMENT_TYPES]: async ({ commit }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const appointmentTypes = await insignaServices.getScheduleAppointmentTypes();

      commit(mutation.SET_APPOINTMENT_TYPES, appointmentTypes);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_SCHEDULES]: async (
    { commit },
    { id, accountId, type, language, appointmentId, appointmentName }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const schedules = await insignaServices.getSchedules({
        id,
        account_id: accountId,
        type,
        language,
        appointment_id: appointmentId,
        appointment_name: appointmentName,
      });

      commit(mutation.SET_SCHEDULES, schedules);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.CREATE_SCHEDULE]: async (
    { commit },
    { accountId, type, language, appointmentId, appointmentName, config }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const schedule = await insignaServices.createSchedule({
        account_id: accountId,
        type,
        language,
        appointment_id: appointmentId,
        appointment_name: appointmentName,
        config,
      });

      commit(mutation.SET_SCHEDULE, schedule.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DELETE_SCHEDULE]: async ({ commit }, { accountId, id }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.deleteSchedule({
        account_id: accountId,
        id,
      });

    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
};
