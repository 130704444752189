export const resetState = () => ({
  error: null,
  loading: false,

  feedbacks: {
    data: [],
    meta: {
      total: 0,
    },
  }
});

export default resetState();
