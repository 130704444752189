import { solicitationStatus } from "../../services/insigna";

export default (value) => {
  if (!value) return "-";

  const status = {
    [solicitationStatus.NOT_STARTED]: "Não iniciado",
    [solicitationStatus.IN_PROGRESS]: "Em andamento",
    [solicitationStatus.FINISHED]: "Finalizado"
  };

  return status[value];
};
