export const resetState = () => ({
  error: null,
  loading: false,

  skillLevelDetails: [],
  testVersions: [],

  solicitationAccounts: {
    data: [],
    meta: {
      current_page: 1,
      total: 0,
      per_page: 15,
    },
  },
  solicitationAccount: {
    data: {},
  },
  count: 0,

  solicitationAccountsSelected: [],
  solicitationAccountsHeaders: [
    { active: true,  sortable: true,  align: "start",  value: "id", text: "Solicitação Empresa ID", },
    { active: true,  sortable: false, align: "start",  value: "solicitor.name", text: "Solicitante Nome", },
    { active: false, sortable: false, align: "start",  value: "solicitor.email", text: "Solicitante Email", },
    { active: true,  sortable: false, align: "start",  value: "organization.name", text: "Organização", },
    { active: true,  sortable: false, align: "start",  value: "account.name", text: "Conta", },
    { active: true,  sortable: false, align: "center", value: "start_at", text: "Data inicial para realização", },
    { active: true,  sortable: false, align: "center", value: "end_at", text: "Prazo para Conclusão", },
    { active: false, sortable: false, align: "center", value: "send_at", text: "Prazo de Envio", },
    { active: false, sortable: false, align: "center", value: "deadline_at", text: "Entregar Até", },
    { active: false, sortable: false, align: "center", value: "deadline", text: "Prazo de Entrega", },
    { active: true,  sortable: false, align: "center", value: "status", text: "Status", },
    { active: false, sortable: false, align: "center", value: "guests.approved", text: "Candidatos Aprovados", },
    { active: false, sortable: false, align: "center", value: "guests.refused", text: "Candidatos Reprovados", },
    { active: false, sortable: false, align: "center", value: "solicitations", text: "Solicitações (Qtd.)", },
    { active: false, sortable: false, align: "center", value: "test_lang", text: "Idioma Teste", },
    { active: false, sortable: false, align: "center", value: "instruction_lang", text: "Idioma Instrução", },
    { active: false, sortable: false, align: "start",  value: "template.name", text: "Template", },
    { active: false, sortable: false, align: "start",  value: "nature.name", text: "Natureza", },
    { active: false, sortable: false, align: "start",  value: "test_goal.name", text: "Objetivo Teste", },
    { active: false, sortable: false, align: "start",  value: "test_type.name", text: "Tipo Teste", },
    { active: false, sortable: false, align: "start",  value: "test_type.automatic_approval", text: "Aprovação Automática", },
    { active: false, sortable: false, align: "center", value: "feedback", text: "Entrega Feedback", },
    { active: false, sortable: false, align: "center", value: "analyze", text: "Análise", },
    { active: false, sortable: false, align: "center", value: "recruitment", text: "Recrutamento", },
    { active: false, sortable: false, align: "center", value: "pdi", text: "PDI", },
    { active: false, sortable: false, align: "center", value: "is_assessor", text: "Assessor", },
    { active: false, sortable: false, align: "center", value: "result_delivery", text: "Entregar Resultado", },
    { active: false, sortable: false, align: "center", value: "result_delivery_deadline", text: "Entregar Resultado para", },
    { active: false, sortable: false, align: "center", value: "created_at", text: "Data Criação", },
    { active: false, sortable: false, align: "center", value: "deleted_at", text: "Data Exclusão", },
  ],
  solicitationAccountsFilters: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 15,
    page: 1,
    sortBy: [],
    sortDesc: [],

    q: null,
    solicitation_account_status_id: null,
    created_at: null,
    end_at: null,
    organization_id: null,
    account_id: null,
    analyze: null,
    feedback: null,
    active: null,
    recruitment: null,
  },
});

export default resetState();
