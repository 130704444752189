import Vue from 'vue'
import cnpj from './cnpj'
import date from './date'
import dateSafe from './date-safe'
import json from './json'
import solicitationStatus from './solicitation-status'
import executionStatus from './execution-status'
import feedbackStatus from './feedback-status'
import language from './language'
import role from './role'
import resultDelivery from './result-delivery'
import solicitationAccountStatus from './solicitation-account-status'
import invoiceStatus from './invoice-status'

Vue.filter('cnpj', cnpj)
Vue.filter('date', date)
Vue.filter('dateSafe', dateSafe)
Vue.filter('json', json)
Vue.filter('solicitationStatus', solicitationStatus)
Vue.filter('solicitationAccountStatus', solicitationAccountStatus)
Vue.filter('executionStatus', executionStatus)
Vue.filter('feedbackStatus', feedbackStatus)
Vue.filter('language', language)
Vue.filter('role', role)
Vue.filter('resultDelivery', resultDelivery)
Vue.filter('invoiceStatus', invoiceStatus)

export { cnpj, date }
