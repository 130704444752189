<template>
  <Layout ref="layout">
    <v-main>
      <router-view />
    </v-main>
  </Layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { insignaServices } from "./services";
import { solicitationAccountStatus } from './services/insigna';
const MINUTES = 60 * 1000;

export default {
  name: "App",

  components: {
    Layout: () => import("./layout"),
  },

  data: () => ({
    timerId: null,
  }),

  computed: {
    ...mapGetters("solicitationAccount", ["getCountSolicitationAccounts"]),
    ...mapState(["packageVersion"]),
    ...mapState("auth", ["userSettings"]),
  },

  methods: {
    ...mapActions("auth", ["getUserInfo", "getNotifications"]),
    ...mapActions("solicitationAccount", ["countSolicitationAccounts"]),
    ...mapActions(["reset"]),
    showNewVersion() {
      this.$swal({
        title: "Nova versão disponível!",
        width: 600,
        padding: "3em",
        background: "#fff url(https://sweetalert2.github.io/images/trees.png)",
        backdrop: `
          rgba(0,0,123,0.4)
          url("https://sweetalert2.github.io/images/nyan-cat.gif")
          left top
          no-repeat
        `,
      }).then(async () => {
        await this.reset();
        window.location.reload();
      });
    },
  },

  async created() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }

    const newVersion = process.env.VUE_APP_VERSION;

    this.$vuetify.theme.dark = this.userSettings.theme === "dark";

    if (insignaServices.authenticated()) {
      await this.getUserInfo();

      this.timerId = setInterval(() => {
        this.getNotifications();
      }, MINUTES);

      if (newVersion !== this.packageVersion) {
        console.info("new version detected, resetting store", {
          newVersion,
          currentVersion: this.packageVersion,
        });

        this.showNewVersion();
      }

      await this.countSolicitationAccounts({
        solicitation_account_status_id: solicitationAccountStatus.WAITING_FOR_INSIGNA,
        active: 1,
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 1,
      });
      this.$refs.layout.updateBadge('Solicitações', this.getCountSolicitationAccounts);
    }
  },

  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>
