import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

export default (value, format = 'DD/MM/YYYY') => {
  if (!value || !dayjs(value).isValid()) return '-'

  return dayjs(value).format(format)
}
