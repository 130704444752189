export const resetState = () => ({
  userLogged: {
    id: 0,
    roles: []
  },
  userSettings: {
    theme: 'light',
    notification: true,
    auto_search: true,
  },
  notifications: [],
  isAuthenticated: false,
  filters: {
    page: 1,
  },
  error: null,
  loading: false,
})

export default resetState()
