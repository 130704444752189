import { resetState } from "./state";
import { mutation } from "./types";

export default {
  [mutation.SET_APPOINTMENT_TYPES]: (state, payload) => {
    state.appointmentTypes = payload;
  },
  [mutation.SET_SCHEDULES]: (state, payload) => {
    state.schedules = payload;
  },
  [mutation.SET_SCHEDULE]: (state, payload) => {
    state.schedule = payload;
  },
  [mutation.SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [mutation.SET_ERROR]: (state, payload) => {
    state.error = payload;
  },
  [mutation.RESET]: (state) => {
    const newState = resetState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
