export const getters = {
  SOLICITATION_ACCOUNTS_HEADERS_ACTIVE: 'solicitationAccountsHeadersActive',
  GET_COUNT_SOLICITATION_ACCOUNTS: 'getCountSolicitationAccounts'
};

export const mutation = {
  SET_SOLICITATION_ACCOUNT: "setSolicitationAccount",
  SET_SOLICITATION_ACCOUNTS: 'setSolicitationAccounts',

  SET_SOLICITATION_ACCOUNTS_HEADERS: "setSolicitationAccountsHeaders",
  SET_SOLICITATION_ACCOUNTS_SELECTED: "setSolicitationAccountsSelected",
  SET_SOLICITATION_ACCOUNTS_FILTERS: "setSolicitationAccountsFilters",
  SET_SKILL_LEVEL_DETAILS: "setSkillLevelDetails",
  SET_COUNT_SOLICITATION_ACCOUNTS: "setCountSolicitationAccounts",

  SET_TEST_VERSIONS: "setTestVersions",

  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  SEARCH_SOLICITATION_ACCOUNT: "searchSolicitationAccount",
  SEARCH_SOLICITATION_ACCOUNTS: "searchSolicitationAccounts",

  UPDATE_SOLICITATION_ACCOUNTS_DEADLINE: 'updateSolicitationAccountsDeadline',
  DELETE_SOLICITATION_ACCOUNT: 'deleteSolicitationAccount',
  MANUAL_APPROVAL: 'manualApproval',
  GET_SKILL_LEVEL_DETAILS: 'getSkillLevelDetails',
  SEND_LOGIN_EMAIL_SOLICITATION_ACCOUNT: 'sendLoginEmailSolicitationAccount',
  USER_REFUSED_APPROVAL_SOLICITATION_ACCOUNT: 'userRefusedApprovalSolicitationAccount',

  UPDATE_SOLICITATION_ACCOUNTS_HEADER: 'updateSolicitationAccountsHeader',
  UPDATE_SOLICITATION_ACCOUNTS_HEADERS: 'updateSolicitationAccountsHeaders',
  UPDATE_SOLICITATION_ACCOUNTS_SELECTED: 'updateSolicitationAccountsSelected',
  UPDATE_SOLICITATION_ACCOUNTS_FILTERS: 'updateSolicitationAccountsFilters',
  CLEAR_FILTERS_SOLICITATION_ACCOUNTS: 'clearFiltersSolicitationAccounts',
  COUNT_SOLICITATION_ACCOUNTS: 'countSolicitationAccounts',

  GET_TEST_VERSIONS: 'getTestVersions',
  CLEAR_TEST_VERSIONS: 'clearTestVersions',

  RESET: "reset",
};

export const TypeGoal = {
  ACCOMPANIMENT: 1,
  PROFICIENCY: 2,
};
