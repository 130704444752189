import erros from "./errors";
import { createInstance } from "../config";

const scheduleOralAPI = createInstance("/v1/admin/schedule/oral");

export async function proposeOral(payload) {
  try {
    return await scheduleOralAPI.post("/propose", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function scheduleOral(payload) {
  try {
    return await scheduleOralAPI.put("", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function doneOral(payload) {
  try {
    return await scheduleOralAPI.put("/done", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function canceledOral(payload) {
  try {
    return await scheduleOralAPI.put("/canceled", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function finishOral(payload) {
  try {
    return await scheduleOralAPI.put("/finished", payload);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}
