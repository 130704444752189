import { createInstance } from "../config";
import erros from "./errors";
import qs from "query-string";

const accountsApi = createInstance("/v1/admin/accounts");

export async function getScheduleAppointmentTypes() {
  try {
    const { data } = await accountsApi.get("schedules/appointment-types");

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getSchedules({
  id,
  account_id,
  type,
  language,
  appointment_id,
  appointment_name,
}) {
  try {
    const data = await accountsApi.get(`${account_id}/schedules`, {
      params: {
        id,
        account_id,
        type,
        language,
        appointment_id,
        appointment_name,
      },
      paramsSerializer: qs.stringify,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function createSchedule({
  account_id,
  type,
  language,
  appointment_id,
  appointment_name,
  config,
}) {
  try {
    const data = await accountsApi.post(`${account_id}/schedules`, {
      account_id,
      type,
      language,
      appointment_id,
      appointment_name,
      config,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function deleteSchedule({ account_id, id }) {
  try {
    await accountsApi.delete(`${account_id}/schedules/${id}`);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}
