export default (value) => {
  const langs = {
    "pt-BR": "Português",
    "en-US": "Inglês",
    "es-ES": "Espanhol",
  };

  if (!value) {
    return "Não definido";
  }

  return langs[value] || value;
};
