import { insignaServices } from "../../../services";
import { resetState } from "./state";
import { mutation, actions } from "./types";

export default {
  [actions.GET_ORGANIZATIONS]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);

      const organizations = await insignaServices.getOrganizations(filters);

      commit(mutation.SET_ORGANIZATIONS, organizations);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_ORGANIZATION]: async ({ commit }, id) => {
    try {
      commit(mutation.SET_LOADING, true);

      const organization = await insignaServices.getOrganization(id);

      commit(mutation.SET_ORGANIZATION, organization.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.CREATE_ORGANIZATION]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const organization = await insignaServices.createOrganization(payload);

      commit(mutation.SET_ORGANIZATION, organization.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_ORGANIZATION]: async ({ commit, state }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const { id } = state.organization;

      await insignaServices.updateOrganization(id, payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_FILTERS]: ({ commit }, filters) => {
    commit(mutation.SET_FILTERS, filters);
  },
  [actions.CLEAR_FILTERS]: ({ commit }) => {
    const reset = resetState()
    commit(mutation.SET_FILTERS, reset.filters);
  },
  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
