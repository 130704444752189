export default value => {
  if (!value) return ''
  const regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/

  if (regex.test(value)) {
    return value.replace(regex, '$1.$2.$3/$4-$5')
  }

  return value
}
