import { userRoleLabel } from "../../../services/insigna";

export const resetState = () => ({
  magicLink: null,
  users: {
    data: [],
    meta: {
      current_page: 1,
      total: 0,
      per_page: 15,
    },
  },
  user: {
    data: {
      id: null,
      email: null,
      name: null,
      role: {
        id: null,
        label: null,
      },
      language: null,
    },
  },
  roles: userRoleLabel,
  filters: {
    page: 1,
  },
  error: null,
  loading: false,
});

export default resetState();
