import { getters } from "./types";
import { can } from '../../../utils';

export default {
  [getters.SOLICITATIONS_HEADERS]: (state, _, rootState) => {
    const userRoles = rootState.auth.userLogged.roles;

    return state.solicitationsHeaders.filter((item) => {
      if (!item.roles) return true;

      return can(item.roles, userRoles);
    });
  },
  [getters.SOLICITATIONS_HEADERS_ACTIVE]: (_, getter) => {
    return getter[getters.SOLICITATIONS_HEADERS].filter((item) => item.active);
  },
};
