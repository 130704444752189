export const getters = {};

export const mutation = {
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",

  SET_FEEDBACKS: "setFeedbacks",

  RESET: "reset",
};

export const actions = {
  GET_FEEDBACKS: 'getFeedbacks',

  RESET: "reset",
};
