export const resetState = () => ({
  appointmentTypes: {
    data: [],
  },
  schedules: {
    data: [],
    meta: {
      total: 0,
    },
  },
  schedule: {
    id: null,
    account: {
      id: null,
      name: null,
    },
    language: null,
    type: null,
    appointment_id: null,
    appointment_name: null,
    config: null,
  },
  scheduleTypes: [
    { text: 'Agendamento de Feedback', value: 'feedback' },
    { text: 'Agendamento Oral', value: 'oral' },
  ],
  scheduleLanguages: [
    { text: "Português", value: "pt-BR" },
    { text: "Inglês", value:"en-US" },
    { text: "Espanhol", value: "es-ES" },
  ],
  error: null,
  loading: false,
});

export default resetState();
