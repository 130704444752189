import auth from './auth'
import user from './user'
import account from './account'
import organization from './organization'
import solicitation from './solicitation'
import solicitationAccount from './solicitation-account'
import nature from './nature'

export default {
  user,
  auth,
  account,
  organization,
  solicitation,
  solicitationAccount,
  nature,
}
