import logger from "./logger";
import persist from "./vuex-persist";

export * from "./logger";
export * from "./vuex-persist";

const isDevelopment = process.env.NODE_ENV !== "production";

const pluginsDevelopment = [logger, persist];

const pluginsProduction = [persist];

export default isDevelopment ? pluginsDevelopment : pluginsProduction;
