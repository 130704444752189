import axios from "axios";
import qs from "query-string";
import router from "../../router";
import { refreshToken } from "./index"

const {
  VUE_APP_CLIENT_ID,
  VUE_APP_CLIENT_SECRET,
  VUE_APP_API_URL,
} = process.env;

const baseURL = VUE_APP_API_URL;

const TOKEN_NAME = "bko:interno:token";

const REFRESH_TOKEN_NAME = "bko:interno:token:refresh";

function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}

function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_NAME);
}

function setToken(token) {
  return localStorage.setItem(TOKEN_NAME, token);
}

function setRefreshToken(token) {
  return localStorage.setItem(REFRESH_TOKEN_NAME, token);
}

function clearToken() {
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
}

export function createInstance(path = "/") {
  const api = axios.create({
    baseURL: `${baseURL}${path}`,
  });

  api.interceptors.request.use(
    (config) => {
      const token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      console.error("> api > interceptors > response", error.response);

      const originalRequest = error.config;
      const payload = qs.parse(originalRequest.data);

      if (payload.grant_type === "refresh_token") {
        clearToken();

        router.push("/login");

        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        console.info("> start refresh token");

        await refreshToken();

        return api(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const erros = {
  INVALID_CREDENTIALS: new Error("Credenciais invalidas"),
  INTERNAL_SERVER_ERROR: new Error(
    "Erro interno no servidor, por favor tente novamente mais tarde!"
  ),
  ALREADY_EMAIL: new Error("O E-mail já existe, por favor informe outro."),
};

export default {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  createInstance,
  env: {
    clientId: VUE_APP_CLIENT_ID,
    clientSecret: VUE_APP_CLIENT_SECRET,
    baseUrl: VUE_APP_API_URL,
  },
};
