export const getters = {
  SOLICITATIONS_HEADERS_ACTIVE: 'solicitationsHeadersActive',
  SOLICITATIONS_HEADERS: 'solicitationsHeaders',
};

export const mutation = {
  SET_SOLICITATION: "setSolicitation",
  SET_SOLICITATION_LOG: 'setSolicitationLog',
  SET_SOLICITATION_MAILS: 'setSolicitationMails',
  SET_SOLICITATIONS: 'setSolicitations',

  SET_SOLICITATIONS_HEADERS: "setSolicitationsHeaders",
  SET_SOLICITATIONS_SELECTED: "setSolicitationsSelected",
  SET_SOLICITATIONS_FILTERS: "setSolicitationsFilters",

  SET_SKILL_LEVELS: "setSkillLevels",

  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  SEARCH_SOLICITATION: "searchSolicitation",
  SEARCH_SOLICITATION_LOGS: "searchSolicitationLogs",
  SEARCH_SOLICITATION_NOTIFICATIONS: "searchSolicitationNotifications",
  SEARCH_SOLICITATIONS: "searchSolicitations",

  RESET_ATTEMPTS: 'resetAttempts',
  UPDATE_SOLICITATIONS_DEADLINE: 'updateSolicitationsDeadline',
  UPDATE_SOLICITATIONS_DEADLINE_DELIVERY: 'updateSolicitationsDeadlineDelivery',
  UPDATE_RESPONSIBLE: 'updateResponsible',
  DELETE_SOLICITATION: 'deleteSolicitation',
  REVERT_DELETE_SOLICITATION: 'revertDeleteSolicitation',
  EXPORT_SOLICITATIONS: 'exportSolicitations',
  RETIFY_SOLICITATIONS_TEST_VERSION: 'retifySolicitationsTestVersion',
  RETIFY_SOLICITATION_TEST_PARTS: 'retifySolicitationTestParts',
  RETIFY_GOAL_PLAN: 'retifyGoalPlan',
  DELIVERY_RESULT: 'deliveryResult',
  ADD_COMMENT: 'addComment',
  ADD_CHEAT: 'addCheat',
  REMOVE_COMMENT: 'removeComment',
  ADD_TEST_ACCOUNT: 'addTestAccount',

  UPDATE_SKILL_LEVELS: 'updateSkillLevels',

  UPDATE_SOLICITATIONS_HEADER: 'updateSolicitationsHeader',
  UPDATE_SOLICITATIONS_HEADERS: 'updateSolicitationsHeaders',
  UPDATE_SOLICITATIONS_SELECTED: 'updateSolicitationsSelected',
  UPDATE_SOLICITATIONS_FILTERS: 'updateSolicitationsFilters',
  CLEAR_FILTERS_SOLICITATIONS: 'clearFiltersSolicitations',
  UPDATE_CLASSIFICATION: 'updateClassification',

  IMPORT_INVOICE: 'importInvoice',
  DOWNLOAD_EXAMPLE_IMPORT_INVOICE_FILE: 'downloadExampleImportInvoiceFile',

  RESET: "reset",
};
