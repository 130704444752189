export const getters = {
  SCHEDULE_TYPES: 'scheduleTypes',
  SCHEDULE_LANGUAGES: 'scheduleLanguages',
}

export const mutation = {
  SET_APPOINTMENT_TYPES: "setAppointmentTypes",
  SET_SCHEDULES: "setSchedules",
  SET_SCHEDULE: "setSchedule",

  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  GET_APPOINTMENT_TYPES: "getAppointmentTypes",
  GET_SCHEDULES: "getSchedules",
  CREATE_SCHEDULE: "createSchedule",
  UPDATE_SCHEDULE: "updateSchedule",
  DELETE_SCHEDULE: "deleteSchedule",

  RESET: "reset",
};
