export const resetState = () => ({
  natures: [],
  testTypes: [],
  filters: {
    active: 1,
  },
  error: null,
  loading: false,
})

export default resetState()
