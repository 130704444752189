export const getters = {
};

export const mutation = {
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  PROPOSE: "propose",
  SCHEDULE: "schedule",
  CANCEL: "cancel",
  DONE: "done",
  FINISH: "finish",

  RESET: "reset",
};
