export const clearEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

/**
 * verify if user has role
 * @param {string[]} rolesCan roles permissions
 * @param {string[]} roles user roles
 * @returns {boolean}
 */
export const can = (rolesCan, roles) => {
  if (!Array.isArray(rolesCan) || !Array.isArray(roles))
    throw new Error("roles must be a Array");
  if (rolesCan.length < 0) throw new Error("Array of roles cannot be empty");

  const permission = rolesCan.filter((role) => roles.includes(role));

  return permission.length > 0;
};
