import qs from "query-string";
import config, { erros } from "./config";

export * from "./solicitation-account";
export * from "./solicitation";
export * from "./feedback";
export * from "./oral";
export * from "./users";
export * from "./nature";
export * from "./schedule-config";

const authApi = config.createInstance("");
const accountApi = config.createInstance("/v1/admin/accounts");
const organizationApi = config.createInstance("/v1/admin/organizations");

export async function login({ username, password }) {
  try {
    const payload = {
      grant_type: "password",
      client_id: config.env.clientId,
      client_secret: config.env.clientSecret,
      username,
      password,
    };
    const payloadStringify = qs.stringify(payload);

    const { data } = await authApi.post("/oauth/token", payloadStringify, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    config.setToken(data.access_token);
    config.setRefreshToken(data.refresh_token);

    return data;
  } catch (error) {
    const status = error.response.status || 500;

    if (status === 400) {
      throw erros.INVALID_CREDENTIALS;
    }

    if (status === 401) {
      throw erros.INVALID_CREDENTIALS;
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function refreshToken() {
  try {
    const payload = {
      grant_type: "refresh_token",
      refresh_token: config.getRefreshToken(),
      client_id: config.env.clientId,
      client_secret: config.env.clientSecret,
    };
    const payloadStringify = qs.stringify(payload);

    const { data } = await authApi.post("/oauth/token", payloadStringify, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    config.setToken(data.access_token);
    config.setRefreshToken(data.refresh_token);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function logout() {
  try {
    await authApi.get("/logout");
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  } finally {
    window.localStorage.clear();
  }
}

export async function me() {
  try {
    const { data } = await authApi.get("/me");

    return {
      ...data.data,
      // FIXME: remover ao API retornar o valor
      roles: ['payment']
    };
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function notifications() {
  try {
    // const { data } = await authApi.get("/me");

    // return data.data;
    return Promise.resolve({
      data: [
        {
          id: 1,
          origin: "Sistema",
        },
        {
          id: 3,
          origin: "Juliana Mosca",
        },
        {
          id: 2,
          origin: "Denilson Souza",
        },
      ],
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getAccounts() {
  try {
    const { data } = await accountApi.get("");

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export function authenticated() {
  const token = config.getToken();

  return token !== undefined && token !== null && token !== "";
}

export async function getOrganizations(filters) {
  try {
    const { data } = await organizationApi.get("", {
      params: { ...filters, active: filters.active ? 1 : 0 },
      paramsSerializer: (query) =>
        qs.stringify(query, { arrayFormat: "bracket" }),
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getOrganization(id) {
  try {
    const { data } = await organizationApi.get(`/${id}`);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function createOrganization(payload) {
  try {
    const { data } = await organizationApi.post("", payload);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateOrganization(id, payload) {
  try {
    const { data } = await organizationApi.put(`/${id}`, payload);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getConsultor() {
  try {
    const consultorsApi = config.createInstance("/v1/admin/consultants");

    const { data } = await consultorsApi.get("");

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getSkillLevels(queryParams) {
  try {
    const skillLevelsApi = config.createInstance("/v1/admin/skill-levels");

    const { data } = await skillLevelsApi.get("", {
      params: queryParams,
      paramsSerializer: (query) =>
        qs.stringify(query, { arrayFormat: "bracket" }),
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export const solicitationAccountStatus = {
  WAITING_FOR_INSIGNA: 1,
  PROCESSED: 2,
  CANCELLED: 3,
};

export const solicitationStatus = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  FINISHED: 3,
};

export const executionStatus = {
  IN_PROGRESS: 1,
  FINISHED: 2,
};

export const resultStatus = {
  FINISHED: 0,
  CORRECTION: 1,
};

export const feedbackStatus = {
  PROPOSED: 0,
  SCHEDULED: 1,
  DONE: 2,
  CANCELED: 3,
  FINISHED: 4,
};

export const userRole = {
  ROLE_ADMIN: 1,
  ROLE_MASTER: 2,
  ROLE_HR: 3,
  ROLE_USER: 4,
  ROLE_APPLICATION: 5,
};

export const userRoleLabel = [
  {
    text: "Administrador",
    value: userRole.ROLE_ADMIN,
  },
  {
    text: "Master",
    label: "RH Master",
    value: userRole.ROLE_MASTER,
  },
  {
    text: "RH",
    label: "RH Parceiro",
    value: userRole.ROLE_HR,
  },
  {
    text: "User",
    label: "Usuário",
    value: userRole.ROLE_USER,
  },
  {
    text: "Application",
    label: "Aplicação",
    value: userRole.ROLE_APPLICATION,
  },
];

export const resultDelivery = {
  ONLY_RH: 1,
  RH_AND_USER: 2,
  ONLY_USER: 3,
};

export const resultDeliveryLabel = [
  { value: resultDelivery.ONLY_RH, text: "Solicitante do teste" },
  {
    value: resultDelivery.RH_AND_USER,
    text: "Candidato/colaborador com o solicitante em cópia",
  },
  { value: resultDelivery.ONLY_USER, text: "Candidato/colaborador" },
];

export const invoiceStatus = {
  PENDING: 'pendente',
  PROCESSED: 'cobrado',
  CANCELED: 'não cobrado',
};

export const invoiceStatusLabel = [
  { value: invoiceStatus.PENDING, text: "Pendente" },
  { value: invoiceStatus.PROCESSED, text: "Cobrado" },
  { value: invoiceStatus.CANCELED, text: "Não cobrado" },
];
