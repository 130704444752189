export const getters = {
  ACCOUNT_LIST: 'accountList',
  ORGANIZATION_LIST: 'organizationList',
};

export const mutation = {
  SET_ORGANIZATION: "setOrganization",
  SET_ORGANIZATIONS: "setOrganizations",
  SET_FILTERS: "setFilters",
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  GET_ORGANIZATIONS: 'getOrganizations',
  GET_ORGANIZATION: 'getOrganization',
  CREATE_ORGANIZATION: 'createOrganization',
  UPDATE_ORGANIZATION: 'updateOrganization',
  UPDATE_FILTERS: 'updateFilters',
  CLEAR_FILTERS: 'clearFilters',

  RESET: "reset",
};
