import dayjs from "dayjs";
import { saveAs } from "file-saver";
import fileDownload from "js-file-download";

import { insignaServices } from "../../../services";
import { resetState } from "./state";
import { mutation, actions } from "./types";

export default {
  [actions.SEARCH_SOLICITATIONS]: async ({ commit, state }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const filters = state.solicitationsFilters;

      const solicitations = await insignaServices.searchSolicitations(filters);

      commit(mutation.SET_SOLICITATIONS, solicitations);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.SEARCH_SOLICITATION_LOGS]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);

      const assessments = await insignaServices.getSolicitationLogs(filters);

      commit(mutation.SET_SOLICITATION_LOG, assessments.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.SEARCH_SOLICITATION_NOTIFICATIONS]: async (
    { commit },
    solicitationId
  ) => {
    try {
      commit(mutation.SET_LOADING, true);

      const mails = await insignaServices.getNotifications(solicitationId);

      commit(mutation.SET_SOLICITATION_MAILS, mails.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },

  [actions.SEARCH_SOLICITATION]: async ({ commit }, token) => {
    try {
      commit(mutation.SET_LOADING, true);

      const solicitations = await insignaServices.searchSolicitations({
        itemsPerPage: 1,
        sortBy: [],
        sortDesc: [],
        page: 1,
        q: token,
        active: true,
      });

      if (solicitations.data[0].token !== token) {
        throw new Error("Solicitação não encontrada");
      }

      commit(mutation.SET_SOLICITATION, solicitations.data[0]);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },

  [actions.EXPORT_SOLICITATIONS]: async ({ commit, state }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const filters = state.solicitationsFilters;

      const csv = await insignaServices.exportSolicitations(filters);

      saveAs(csv, `solicitacoes_${dayjs().format("YYYY-MM-DD")}.xls`);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DELIVERY_RESULT]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.deliveryResult(payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.ADD_CHEAT]: async ({ commit }, { solicitationIds, cheat, comment }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.addCheat(solicitationIds, cheat, comment);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.ADD_COMMENT]: async ({ commit }, { solicitationIds, comment }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.addComment(solicitationIds, comment);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.REMOVE_COMMENT]: async ({ commit }, commentId) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.removeComment(commentId);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DELETE_SOLICITATION]: async ({ commit }, solicitationIds) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.deleteSolicitation(solicitationIds);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.REVERT_DELETE_SOLICITATION]: async ({ commit }, solicitationIds) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.revertDeleteSolicitation(solicitationIds);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RESET_ATTEMPTS]: async ({ commit }, solicitationIds) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.resetAttemps(solicitationIds);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SOLICITATIONS_DEADLINE]: async (
    { commit },
    { solicitationIds, deadline, send_email, to, cc, bcc }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.updateDeadlineSolicitation(
        solicitationIds,
        deadline,
        send_email,
        to,
        cc,
        bcc
      );
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SOLICITATIONS_DEADLINE_DELIVERY]: async (
    { commit },
    { solicitationIds, deadline }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.updateDeadlineDeliverySolicitation(
        solicitationIds,
        deadline
      );
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_RESPONSIBLE]: async (
    { commit },
    { solicitationIds, userId }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.updateResponsible(solicitationIds, userId);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RETIFY_SOLICITATIONS_TEST_VERSION]: async (
    { commit },
    { solicitationIds, testVersion }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.retifyTestVersionSolicitation(
        solicitationIds,
        testVersion
      );
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RETIFY_SOLICITATION_TEST_PARTS]: async (
    { commit },
    { solicitationId, parts }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.retifySolicitationTestParts(solicitationId, parts);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.RETIFY_GOAL_PLAN]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.retifyGoalPlan(payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SKILL_LEVELS]: async ({ commit }, queryParams) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const skillLevels = await insignaServices.getSkillLevels(queryParams);

      commit(mutation.SET_SKILL_LEVELS, skillLevels.data);
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.ADD_TEST_ACCOUNT]: async ({ commit }, payload) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.addTestAccount(payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_CLASSIFICATION]: async ({ commit }, { solicitationId, payload }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.updateResult(solicitationId, payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },

  [actions.IMPORT_INVOICE]: async (
    { commit },
    {
      file,
      delimiter = ";",
      fnCallback = (progressEvent) => {
        console.log("fnCallback", progressEvent);
      },
    }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const formData = new FormData();
      // FIXME: corrigir tipo do arquivo
      formData.append("file_to_upload", new Blob([file], { type: "text/csv" }));
      formData.append("delimiter", delimiter);

      await insignaServices.importInvoice(formData, fnCallback);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DOWNLOAD_EXAMPLE_IMPORT_INVOICE_FILE]: () => {
    const rows = [
      ['token', 'status_faturamento', 'data_faturamento', 'numero_da_nota_fiscal', 'data_nao_cobranca', 'motivo_nao_cobranca'],
      ['267740', 'cobrado', '29-03-2023', 'REF_EXAMPLE', '', ''],
      ['267739', 'não cobrado', '', '', '29-03-2023', 'Avaliacao de teste'],
    ]
    const csvContent = rows.map(e => e.join(';')).join('\n')

    const encodedUri = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    })

    fileDownload(encodedUri, 'exemplo_importacao_faturamento.csv')
  },

  [actions.UPDATE_SOLICITATIONS_HEADER]: ({ commit, state }, { value }) => {
    const newHeaders = state.solicitationsHeaders.map((header) => {
      if (header.value === value) {
        return { ...header, active: !header.active };
      }

      return header;
    });

    commit(mutation.SET_SOLICITATIONS_HEADERS, newHeaders);
  },
  [actions.UPDATE_SOLICITATIONS_HEADERS]: ({ commit }, headers) => {
    commit(mutation.SET_SOLICITATIONS_HEADERS, headers);
  },
  [actions.CLEAR_FILTERS_SOLICITATIONS]: ({ commit }) => {
    const stateReset = resetState();

    commit(mutation.SET_SOLICITATIONS_FILTERS, stateReset.solicitationsFilters);
  },
  [actions.UPDATE_SOLICITATIONS_FILTERS]: ({ commit }, filters) => {
    commit(mutation.SET_SOLICITATIONS_FILTERS, filters);
  },
  [actions.UPDATE_SOLICITATIONS_SELECTED]: ({ commit }, selected) => {
    commit(mutation.SET_SOLICITATIONS_SELECTED, selected);
  },

  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
