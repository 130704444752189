import { resetState } from "./state";
import { mutation } from "./types";

export default {
  [mutation.SET_ERROR]: (state, error) => {
    state.error = error;
  },
  [mutation.SET_LOADING]: (state, loading) => {
    state.loading = loading;
  },

  [mutation.SET_FEEDBACKS]: (state, payload) => {
    state.feedbacks = payload;
  },

  [mutation.RESET]: (state) => {
    const newState = resetState();

    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
