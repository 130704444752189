import Vue from "vue";

import "./helpers/filters";
import "./helpers/mixins";
import "./helpers/components";

import store from "./store";
import router from "./router";
import plugins from "./plugins";

import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  ...plugins,
  render: (h) => h(App),
}).$mount("#app");
