import { getters } from './types'

export default {
  [getters.ROLES_DICIONARY]: (state) => {
    state.roles.reduce((prev, current) => {
      prev[current.value] = current;

      return prev;
    }, {});
  },
  [getters.USER_ADMIN]: (state) => {
    return state.users.data
  }
};
