<template>
  <section class="wrapper" :style="{ backgroundImage: `url(${Login})` }">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="5" lg="4" xl="3">
          <v-card :style="{ background: 'transparent' }" elevation="0">
            <v-card-text>
              <v-img
                class="mb-6"
                :src="Icon"
                alt="Logo Insigna"
                height="80"
                contain
              />

              <div class="mb-6 mt-12">
                <h1 class="text-h5 text-center font-weight-black" style="color: white">
                  Insigna Teste Online - Backoffice Interno
                </h1>
                <hr class="line mt-4" />
              </div>

              <v-form ref="form" @submit.prevent="handleLogin">
                <v-text-field
                  v-model="form.username"
                  :rules="[rules.required, rules.email]"
                  :disabled="loading"
                  label="E-mail*"
                  color="background"
                  flat
                  solo
                  background-color="background"
                  placeholder=" "
                  type="email"
                  prepend-inner-icon="mdi-account"
                  hide-details="auto"
                />

                <v-text-field
                  v-model="form.password"
                  :rules="[rules.required]"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye=off'"
                  :disabled="loading"
                  label="Senha*"
                  color="background"
                  flat
                  solo
                  placeholder=" "
                  autocomplete="off"
                  class="mt-5"
                  prepend-inner-icon="mdi-lock"
                  hide-details="auto"
                  @click:append="showPassword = !showPassword"
                />

                <v-error store="auth" :timer="false" />

                <v-btn
                  type="submit"
                  class="mt-5"
                  color="background"
                  :loading="loading"
                  block
                  outlined
                  x-large
                >
                  Entrar
                </v-btn>
              </v-form>
            </v-card-text>
            <v-spacer />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { userRole } from "../services/insigna";
import rules from "../utils/rules";

export default {
  data: () => ({
    Icon: 'https://tostorageprod.blob.core.windows.net/public/insigna_logo_white.svg',
    Login: 'https://tostorageprod.blob.core.windows.net/public/bg_gradiente_three_colors_01.svg',
    rules,
    form: {
      username: null,
      password: null,
    },
    showPassword: false,
  }),
  computed: {
    ...mapState("auth", ["loading", "error", "userLogged"]),
  },
  methods: {
    ...mapActions("auth", ["login", "logout", "getUserInfo"]),
    async handleLogin() {
      const { redirect } = this.$route.query;

      await this.login(this.form);

      if (this.error) return;

      await this.getUserInfo();

      if (this.userLogged.role.id !== userRole.ROLE_ADMIN) {
        this.$store.commit("auth/setError", "Acesso negado, você não é administrador!");

        this.logout();

        return;
      }

      if (redirect) this.$router.push(redirect);

      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #ddd;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.line {
  border: #fff 1px solid;
}
</style>
