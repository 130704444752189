import validator from 'validator'

export default {
  required: (v) => !!v || "Campo obrigatório",
  maxLength: (max) => (v) => (v && v.length <= max) || "Campo inválido",
  minLength: (min) => (v) => (v && v.length >= min) || "Campo inválido",
  email: v => {
    if (!v) return true

    return validator.isEmail(v) || 'E-mail inválido'
  },
  notIn: (list = [], message = "Valor inválido") => (v) => {
    if (!v) return true;

    console.log({ list, v, message });

    console.log(list.includes(v));

    return !list.filter((k) => !!k).includes(v) || message;
  },
  strongPassword: v => {
    if (!v) return true

    if (validator.matches(v, new RegExp('^(?=.*[a-z])(?=.{1,})')) === false) {
      return 'Deve conter ao menos uma letra minúscula.'
    }
    if (validator.matches(v, new RegExp('^(?=.*[A-Z])(?=.{1,})')) === false) {
      return 'Deve conter ao menos uma letra maiúscula.'
    }
    if (validator.matches(v, new RegExp('^(?=.*[0-9])(?=.{1,})')) === false) {
      return 'Deve conter ao menos um número.'
    }
    if (
      validator.matches(v, new RegExp('^(?=.*[!@#$.%^&*])(?=.{1,})')) === false
    ) {
      return 'Deve conter ao menos um caracter especial (!@#$.%^&).'
    }

    return (
      validator.matches(v, new RegExp('^(?=.{8,})')) ||
      'Deve conter no mínimo 8 letras.'
    )
  },
};
