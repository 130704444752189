import { resetState } from "./state";
import { mutation } from "./types";

export default {
  [mutation.SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [mutation.SET_ERROR]: (state, payload) => {
    state.error = payload;
  },

  [mutation.SET_SOLICITATION_ACCOUNT]: (state, payload) => {
    state.solicitationAccount.data = payload;
  },
  [mutation.SET_SKILL_LEVEL_DETAILS]: (state, payload) => {
    state.skillLevelDetails = payload;
  },
  [mutation.SET_SOLICITATION_ACCOUNT_MAILS]: (state, payload) => {
    state.solicitationAccount.mails = payload;
  },
  [mutation.SET_SOLICITATION_ACCOUNTS]: (state, payload) => {
    state.solicitationAccounts = payload;
  },
  [mutation.SET_SKILL_LEVELS]: (state, payload) => {
    state.skillLevels = payload;
  },

  [mutation.SET_SOLICITATION_ACCOUNTS_HEADERS]: (state, payload) => {
    state.solicitationAccountsHeaders = payload;
  },
  [mutation.SET_SOLICITATION_ACCOUNTS_FILTERS]: (state, payload) => {
    state.solicitationAccountsFilters = payload;
  },
  [mutation.SET_SOLICITATION_ACCOUNTS_SELECTED]: (state, payload) => {
    state.solicitationAccountsSelected = payload;
  },
  [mutation.SET_COUNT_SOLICITATION_ACCOUNTS]: (state, payload) => {
    state.count = payload;
  },
  [mutation.SET_TEST_VERSIONS]: (state, payload) => {
    state.testVersions = payload;
  },

  [mutation.RESET]: (state) => {
    const newState = resetState();

    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
