<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="d-flex align-center">
          <h1 data-cy="h1_home">Home</h1>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>
