export const resetState = () => ({
  organizations: {
    data: [],
    meta: {
      total: 0,
    },
  },
  filters: {
    page: 1,
    q: '',
    active: true,
    cnpj: '',
  },
  error: null,
  loading: false,
  organization: {
    active: 0,
    id: null,
    name: null,
    company_name: null,
    accounts: [],
  },
})

export default resetState()
