export const getters = {
};

export const mutation = {
  SET_IS_AUTHENTICATED: "setIsAuthenticated",
  SET_USER_LOGGED: "setUserLogged",
  SET_FILTERS: "setFilters",
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  SET_SETTINGS: "setSettings",
  SET_NOTIFICATIONS: "setNotifications",
  RESET: "reset",
};

export const actions = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  GET_USER_INFO: 'getUserInfo',
  UPDATE_SETTINGS: 'updateSettings',
  GET_NOTIFICATIONS: 'getNotifications',
  RESET: "reset",
};
