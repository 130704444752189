import qs from "query-string";
import erros from "./errors";
import { createInstance } from "../config";
import { clearEmpty } from "../../../utils";

const usersApi = createInstance("/v1/admin/users");

export async function getUsers({
  itemsPerPage,
  active,
  sortBy,
  sortDesc,
  ...filters
}) {
  try {
    const { data } = await usersApi.get("", {
      params: clearEmpty({
        ...filters,
        per_page: itemsPerPage,
        active: active ? null : 1,
        sort_by: sortBy,
        sort_desc: sortDesc?.map((item) => item ? 1 : 0),
      }),
      paramsSerializer: (query) =>
        qs.stringify(query, { arrayFormat: "bracket" }),
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getUser(userId) {
  try {
    const { data } = await usersApi.get(`/${userId}`);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function deleteUser(userId) {
  try {
    await usersApi.delete(`/${userId}`);
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function createUser(payload) {
  try {
    const { data } = await usersApi.post("", payload);

    return data;
  } catch (error) {
    const { status } = error.response;

    if (status === 422) {
      throw erros.ALREADY_EMAIL;
    }

    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function updateUser(userId, payload) {
  try {
    const { data } = await usersApi.put(`/${userId}`, payload);

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function generateMagicLink(userId) {
  try {
    await usersApi.post("/magic-link", {
      user_id: userId,
    });

    const { data } = await usersApi.get("/magic-link", {
      params: {
        user_id: userId,
      },
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function resetPassword(userId) {
  try {
    await usersApi.post("/password/reset", {
      user_id: userId,
    });
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export function exportUsers(filters) {
  return usersApi.get("/export", {
    params: filters,
    paramsSerializer: qs.stringify,
    responseType: "blob",
  });
}

export function importUsers(payload, fnCallback) {
  return usersApi.post("/import", payload, {
    onUploadProgress: (e) => fnCallback(e),
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
