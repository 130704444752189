import { getters } from "./types";

export default {
  [getters.SOLICITATION_ACCOUNTS_HEADERS_ACTIVE]: (state) => {
    return state.solicitationAccountsHeaders.filter((item) => item.active);
  },
  [getters.GET_COUNT_SOLICITATION_ACCOUNTS]: (state) => {
    return state.count > 99 ? '99+' : state.count;
  },
};
