import { feedbackStatus } from "../../services/insigna";

export default (value = null) => {
  if (value === null) return "-";

  const status = {
    [feedbackStatus.PROPOSED]: "Proposto",
    [feedbackStatus.SCHEDULED]: "Agendado",
    [feedbackStatus.DONE]: "Feito",
    [feedbackStatus.CANCELED]: "Cancelado",
    [feedbackStatus.FINISHED]: "Finalizado",
  };

  return status[value] || "-";
};
