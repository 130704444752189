export const getters = {
};

export const mutation = {
  SET_ACCOUNTS: "setAccounts",
  SET_FILTERS: "setFilters",
  SET_ERROR: "setError",
  SET_LOADING: "setLoading",
  RESET: "reset",
};

export const actions = {
  GET_ACCOUNTS: 'getAccounts',

  RESET: "reset",
};
