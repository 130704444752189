import { solicitationAccountStatus } from "../../services/insigna";

export default (value) => {
  if (!value) return "-";

  const status = {
    [solicitationAccountStatus.WAITING_FOR_INSIGNA]: "Aguardando Aprovação",
    [solicitationAccountStatus.PROCESSED]: "Processado",
    [solicitationAccountStatus.CANCELLED]: "Cancelado"
  };

  return status[value];
};
