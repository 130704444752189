import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";
import plugins, { vuexPersist } from "./plugins";

Vue.use(Vuex);

const resetModules = async (module = null, modules, store) => {
  const dispatchers = [];

  Object.keys(modules).forEach((key) => {
    if (modules[key].modules) {
      if (module) {
        resetModules(`${module}/${key}`, modules[key].modules, store);
      } else {
        resetModules(key, modules[key].modules, store);
      }
    }

    if (!modules[key].actions.reset) return;

    if (module) {
      dispatchers.push(store.dispatch(`${module}/${key}/reset`));
    } else {
      dispatchers.push(store.dispatch(`${key}/reset`));
    }
  });

  await Promise.all(dispatchers);
};

const resetState = () => ({
  languages: [
    { text: "Inglês", value: "en-US" },
    { text: "Espanhol", value: "es-ES" },
    { text: "Português", value: "pt-BR" },
  ],
  packageVersion: process.env.VUE_APP_VERSION || "0",
});

export default new Vuex.Store({
  state: resetState(),
  getters: {
    appVersion: (state) => state.packageVersion,
    languagesDic: (state) => {
      return state.languages.reduce((prev, current) => {
        prev[current.value] = current;

        return prev;
      }, {});
    },
  },
  mutations: {
    reset: (state) => {
      const newState = resetState();

      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  actions: {
    async reset(store) {
      window.localStorage.removeItem(vuexPersist.key);

      await resetModules(null, modules, store);
      await store.commit("reset");
    },
  },
  plugins,
  modules,
  strict: process.env.NODE_ENV !== "production",
});
