import { getters } from './types'

export default {
  [getters.ACCOUNT_LIST](state) {
    return state.organization.accounts.map((account) => ({
      text: account.name,
      value: account.id,
    }));
  },
  [getters.ORGANIZATION_LIST](state) {
    return state.organizations.data.map((organization) => ({
      text: organization.name,
      value: organization.id,
    }));
  },
}
