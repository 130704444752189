import Vue from 'vue'
import { mapState } from 'vuex'
import { can } from '../../utils'

// can method is a mixin vue and verify if user logged can see or do action
Vue.mixin({
    computed: {
        ...mapState({
            userLogged: (state) => state.auth.userLogged,
        }),
    },
    methods: {
        // FIXME: funcao temporaria até mudarmos os sistema de ROLE dos usuarios logado
        can: function (roles) {
            const usersIdFinance = [
                305499, // itor.isaias@gmail.com
                321762, // igor.wanderley@insignaconsultoria.com.br
                305851, // angela.coy.admin@insignaconsultoria.com.br
                323999, // thomas.osorio6913@hotmail.com
                305500, // juliana.mosca@insignaconsultoria.com.br
            ]

            if (roles.includes('payment') && usersIdFinance.includes(this.userLogged.id)) {
                return true;
            }

            return can(roles, this.userLogged.roles)
        }
    }
})