import { getters } from "./types";

export default {
    [getters.SCHEDULE_TYPES](state) {
        return state.scheduleTypes
    },
    [getters.SCHEDULE_LANGUAGES](state) {
      return state.scheduleLanguages;
    },
}
