import { insignaServices } from "../../../services";
import { resetState } from "./state";
import { mutation, actions } from "./types";

export default {
  [actions.SEARCH_SOLICITATION_ACCOUNTS]: async ({ commit, state }) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const filters = state.solicitationAccountsFilters;

      const solicitationAccounts = await insignaServices.searchSolicitationAccounts(
        filters
      );

      commit(mutation.SET_SOLICITATION_ACCOUNTS, solicitationAccounts);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.SEARCH_SOLICITATION_ACCOUNT]: async ({ commit }, token) => {
    try {
      commit(mutation.SET_LOADING, true);

      const solicitationAccounts = await insignaServices.searchSolicitationAccounts(
        {
          itemsPerPage: 1,
          page: 1,
          q: token,
          active: true,
        }
      );

      if (solicitationAccounts.data[0].token !== token) {
        throw new Error("Solicitação não encontrada");
      }

      commit(mutation.SET_SOLICITATION_ACCOUNT, solicitationAccounts.data[0]);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.DELETE_SOLICITATION_ACCOUNT]: async (
    { commit },
    solicitationAccountIds
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.deleteSolicitationAccount(solicitationAccountIds);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.MANUAL_APPROVAL]: async (
    { commit },
    {
      solicitation_account_id,
      send_notification,
      test_version_id,
      refused_user_observation,
      users,
    }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.manualApproval({
        solicitation_account_id,
        users,
        send_notification,
        test_version_id,
        refused_user_observation,
      });
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_SKILL_LEVEL_DETAILS]: async (
    { commit },
    solicitation_account_id
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const {
        data: skillLevelDetails,
      } = await insignaServices.getSkillLevelDetails(solicitation_account_id);

      commit(mutation.SET_SKILL_LEVEL_DETAILS, skillLevelDetails);
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.GET_TEST_VERSIONS]: async (
    { commit },
    {
      solicitation_account_id,
      skill_level_detail_id = null
    }
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_TEST_VERSIONS, []);

      const { data: testVersions } = await insignaServices.getTestVersions(
        solicitation_account_id,
        skill_level_detail_id
      );

      commit(mutation.SET_TEST_VERSIONS, testVersions);
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.SEND_LOGIN_EMAIL_SOLICITATION_ACCOUNT]: async (
    { commit },
    payload
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.sendLoginEmailSolicitationAccount(payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.USER_REFUSED_APPROVAL_SOLICITATION_ACCOUNT]: async (
    { commit },
    payload
  ) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      await insignaServices.userRefusedApprovalSolicitationAccount(payload);
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SOLICITATION_ACCOUNTS_HEADER]: (
    { commit, state },
    { value }
  ) => {
    const newHeaders = state.solicitationAccountsHeaders.map((header) => {
      if (header.value === value) {
        return { ...header, active: !header.active };
      }

      return header;
    });

    commit(mutation.SET_SOLICITATION_ACCOUNTS_HEADERS, newHeaders);
  },
  [actions.COUNT_SOLICITATION_ACCOUNTS]: async ({ commit }, filters) => {
    try {
      commit(mutation.SET_LOADING, true);
      commit(mutation.SET_ERROR, null);

      const solicitationAccounts = await insignaServices.searchSolicitationAccounts(
        filters
      );

      commit(
        mutation.SET_COUNT_SOLICITATION_ACCOUNTS,
        solicitationAccounts.meta.total
      );
    } catch (error) {
      commit(mutation.SET_ERROR, error.message);
    } finally {
      commit(mutation.SET_LOADING, false);
    }
  },
  [actions.UPDATE_SOLICITATION_ACCOUNTS_HEADERS]: ({ commit }, headers) => {
    commit(mutation.SET_SOLICITATION_ACCOUNTS_HEADERS, headers);
  },
  [actions.CLEAR_FILTERS_SOLICITATION_ACCOUNTS]: ({ commit }) => {
    const stateReset = resetState();

    commit(
      mutation.SET_SOLICITATION_ACCOUNTS_FILTERS,
      stateReset.solicitationAccountsFilters
    );
  },
  [actions.UPDATE_SOLICITATION_ACCOUNTS_FILTERS]: ({ commit }, filters) => {
    commit(mutation.SET_SOLICITATION_ACCOUNTS_FILTERS, filters);
  },
  [actions.UPDATE_SOLICITATION_ACCOUNTS_SELECTED]: ({ commit }, selected) => {
    commit(mutation.SET_SOLICITATION_ACCOUNTS_SELECTED, selected);
  },

  [actions.CLEAR_TEST_VERSIONS]: ({ commit }) => {
    commit(mutation.SET_TEST_VERSIONS);
  },

  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
