import Vue from "vue";
import VueRouter from "vue-router";
import { insignaServices } from "../services";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      guest: true,
    },
    component: Login,
  },
  {
    path: "/organization",
    name: "Organization",
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "../views/Organization/List.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "../views/Profile/index.vue"
      ),
  },
  {
    path: "/organization/:id",
    props: true,
    name: "OrganizationDetail",
    component: () =>
      import(
        /* webpackChunkName: "organization_detail" */ "../views/Organization/Detail.vue"
      ),
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users/List/index.vue"),
  },
  {
    path: "/users/:id",
    name: "UserDetail",
    component: () =>
      import(/* webpackChunkName: "user_detail" */ "../views/Users/Detail/index.vue"),
  },
  {
    path: "/assessment",
    name: "Assessment",
    component: () =>
      import(/* webpackChunkName: "assessment" */ "../views/Assessments/List/index.vue"),
  },
  {
    path: "/assessment/:token",
    name: "AssessmentDetail",
    component: () =>
      import(/* webpackChunkName: "assessment_detail" */ "../views/Assessments/Detail/index.vue"),
  },
  {
    path: "/solicitations",
    name: "Solicitations",
    component: () =>
      import(/* webpackChunkName: "assessment" */ "../views/Solicitations/List/index.vue"),
  },
  {
    path: "/solicitations/:id",
    name: "SolicitationsDetail",
    component: () =>
      import(/* webpackChunkName: "assessment_detail" */ "../views/Solicitations/Detail/index.vue"),
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    component: () =>
      import(/* webpackChunkName: "feedbacks" */ "../views/Feedbacks/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (insignaServices.authenticated()) {
    next();
  } else if (to.meta.guest) {
    next();
  } else {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath,
      },
    });
  }
});

export default router;
