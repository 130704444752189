import { mutation, actions } from "./types";
import { insignaServices } from "../../../../../services";

export default {
  [actions.PROPOSE]: async (
    { commit },
    { solicitation_id, to, cc, bcc, send_email, schedule_link = null }
  ) => {
    let feedback = null;

    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const { data } = await insignaServices.proposeFeedback({
        solicitation_id,
        to,
        cc,
        bcc,
        send_email: send_email ? 1 : 0,
        schedule_link
      });

      feedback = data.data;
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }

    return feedback;
  },

  [actions.SCHEDULE]: async ({ commit }, { feedback_id, date, time }) => {
    let feedback = null;

    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const { data } = await insignaServices.scheduleFeedback({
        feedback_id,
        date,
        time,
      });

      feedback = data.data;
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }

    return feedback;
  },

  [actions.CANCEL]: async ({ commit }, { feedback_id, note }) => {
    let feedback = null;

    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const { data } = await insignaServices.canceledFeedback({
        feedback_id,
        note,
      });

      feedback = data.data;
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }

    return feedback;
  },

  [actions.DONE]: async (
    { commit },
    { feedback_id, realized_at, consultant_id }
  ) => {
    let feedback = null;

    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const { data } = await insignaServices.doneFeedback({
        feedback_id,
        realized_at,
        consultant_id,
      });

      feedback = data.data;
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }

    return feedback;
  },

  [actions.FINISH]: async ({ commit }, { feedback_id, realized_at, note }) => {
    let feedback = null;

    try {
      commit(mutation.SET_ERROR, null);
      commit(mutation.SET_LOADING, true);

      const { data } = await insignaServices.finishFeedback({
        feedback_id,
        realized_at,
        note,
      });

      feedback = data.data;
    } catch (error) {
      commit(mutation.SET_ERROR, error);
    } finally {
      commit(mutation.SET_LOADING, false);
    }

    return feedback;
  },

  [actions.RESET]: ({ commit }) => {
    commit(mutation.RESET);
  },
};
