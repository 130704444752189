import { resetState } from "./state";
import { mutation } from "./types";

export default {
  [mutation.SET_IS_AUTHENTICATED]: (state, payload) => {
    state.isAuthenticated = payload;
  },
  [mutation.SET_USER_LOGGED]: (state, payload) => {
    state.userLogged = payload;
  },
  [mutation.SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
  [mutation.SET_FILTERS]: (state, payload) => {
    state.filters = payload;
  },
  [mutation.SET_ERROR]: (state, payload) => {
    state.error = payload;
  },
  [mutation.SET_SETTINGS]: (state, payload) => {
    state.userSettings = {
      ...state.userSettings,
      ...payload
    };
  },
  [mutation.SET_NOTIFICATIONS]: (state, payload) => {
    state.notifications = payload;
  },
  [mutation.RESET]: (state) => {
    const newState = resetState();

    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};
