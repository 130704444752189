import { resetState } from './state'
import { mutation } from './types'

export default {
  [mutation.SET_ACCOUNTS]: (state, payload) => {
    state.accounts = payload
  },
  [mutation.SET_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [mutation.SET_FILTERS]: (state, payload) => {
    state.filters = payload
  },
  [mutation.SET_ERROR]: (state, payload) => {
    state.error = payload
  },
  [mutation.RESET]: (state) => {
    const newState = resetState()

    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}
