export const resetState = () => ({
  error: null,
  loading: false,

  solicitations: {
    data: [],
    meta: {
      current_page: 1,
      total: 0,
      per_page: 15,
    },
  },
  solicitation: {
    logs: [],
    data: {},
    mails: [],
  },
  skillLevels: [],

  solicitationsSelected: [],
  solicitationsHeaders: [
    {
      active: true,
      text: "Solicitação ID",
      value: "id",
      sortable: true,
      align: "start",
    },
    {
      active: false,
      text: "Usuário ID",
      value: "user.id",
      sortable: true,
      align: "start",
    },
    {
      active: true,
      text: "Nome do usuário",
      value: "user.name",
      sortable: true,
      align: "start",
    },
    {
      active: false,
      text: "Email do usuário",
      value: "user.email",
      sortable: true,
      align: "start",
    },
    {
      active: false,
      text: "Idioma do usuário",
      value: "user.language",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Perfil do usuário",
      value: "user.role",
      sortable: true,
      align: "start",
    },
    {
      active: true,
      text: "Token",
      value: "token",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Organização ID",
      value: "user.organization_id",
      sortable: true,
      align: "start",
    },
    {
      active: false,
      text: "Organização",
      value: "user.organization_name",
      sortable: true,
      align: "start",
    },
    {
      active: false,
      text: "Conta ID",
      value: "user.account_id",
      sortable: true,
      align: "start",
    },
    {
      active: true,
      text: "Conta",
      value: "user.account_name",
      sortable: true,
      align: "start",
    },
    {
      active: true,
      text: "Responsável",
      value: "responsible",
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Consultores",
      value: "consultant",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Data de criação",
      value: "created_at",
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Data de exclusão",
      value: "deleted_at",
      sortable: false,
      align: "center",
    },
    {
      active: true,
      text: "Data inicial para realização",
      value: "start_at",
      sortable: true,
      align: "center",
    },
    {
      active: true,
      text: "Prazo para conclusão",
      value: "end_at",
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Prazo 30 dias",
      value: "deadline_at",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Data de início para realização",
      value: "execution.start_at",
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Data de conclusão da execução",
      value: "execution.end_at",
      sortable: true,
      align: "center",
    },
    {
      active: true,
      text: "Status",
      value: "status",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Status faturamento",
      value: "invoice.status_id",
      sortable: false,
      align: "start",
      roles: ["payment"],
    },
    {
      active: false,
      text: "Data faturamento",
      value: "invoice.processed_at",
      sortable: false,
      align: "start",
      roles: ["payment"],
    },
    {
      active: false,
      text: "Número da nota fiscal",
      value: "invoice.ref",
      sortable: false,
      align: "start",
      roles: ["payment"],
    },
    {
      active: false,
      text: "Data não cobranca",
      value: "invoice.canceled_at",
      sortable: false,
      align: "start",
      roles: ["payment"],
    },
    {
      active: false,
      text: "Motivo não cobranca",
      value: "invoice.canceled_reason",
      sortable: false,
      align: "start",
      roles: ["payment"],
    },
    {
      active: false,
      text: "Envio do resultado para",
      value: "result_delivery",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Prazo de entrega",
      value: "result_delivery_deadline_at",
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Data de entrega",
      value: "execution.result.delivery_at",
      sortable: false,
      align: "center",
    },
    {
      text: "Pontuação",
      value: "execution.result.score",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Classificação",
      value: "execution.result.skill_level",
      active: false,
      sortable: true,
      align: "center",
    },
    {
      active: false,
      text: "Status Feedback",
      value: "feedbackStatus",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Status Oral",
      value: "oralStatus",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "ID do teste",
      value: "test_version.id",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Nome do teste",
      value: "test_version.name",
      sortable: false,
      align: "start",
    },
    {
      active: false,
      text: "Natureza",
      value: "nature.name",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Objetivo do teste",
      value: "test_goal.name",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Tipo do teste",
      value: "test_type.name",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Feedback",
      value: "feedback",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Regra do Oral",
      value: "execution.result.speak_rule_applied",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Uso de I.A.",
      value: "execution.has_cheat",
      sortable: false,
      align: "center",
    },
    {
      text: "Análise",
      value: "analyze",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "R&S",
      value: "recruitment",
      active: false,
      sortable: true,
      align: "center",
    },
    {
      text: "PDI",
      value: "pdi",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "Nome do Solicitante",
      value: "solicitor.name",
      sortable: false,
      align: "center",
    },
    {
      active: false,
      text: "E-mail do Solicitante",
      value: "solicitor.email",
      sortable: false,
      align: "center",
    },
    {
      text: "Meta final Solicitação",
      value: "solicitation_account.goal_finished_name",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Prazo meta estabelecida para próxima avaliação Solicitação",
      value: "solicitation_account.period",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Meta estabelecida Solicitação",
      value: "solicitation_account.goal",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Meta final Usuário",
      value: "execution.result.user_goal_plan.goal_finished",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Prazo meta estabelecida para próxima avaliação Usuário",
      value: "execution.result.user_goal_plan.period",
      active: false,
      sortable: false,
      align: "center",
    },
    {
      text: "Meta estabelecida Usuário",
      value: "execution.result.user_goal_plan.goal_established",
      active: false,
      sortable: false,
      align: "center",
    },
  ],
  solicitationsFilters: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 15,
    page: 1,
    sortBy: [],
    sortDesc: [],

    q: null,
    organization_id: null,
    account_id: null,

    responsible_id: null,
    consultant_id: null,

    solicitation_status_id: null,
    solicitation_start_date: null,
    solicitation_end_date: null,

    execution_status_id: null,
    execution_start_date: null,
    execution_end_date: null,

    created_at_start_date: null,
    created_at_end_date: null,

    result_delivery_start_date: null,
    result_delivery_end_date: null,

    delivery_result: null,
    feedback_pending: null,

    invoice_status_id: null,

    solicitation_expired: null,
    active: null,
    feedback: null,
    analyze: null,
    recruitment: null,
    has_cheat: null,
    speak_rule_applied: null,

    nature: null,
    test_type: null,

    moreFilters: null,
  },
});

export default resetState();
