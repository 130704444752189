import qs from "query-string";
import erros from "./errors";
import { createInstance } from "../config";

const natureApi = createInstance("/v1/admin/natures");

export async function getNatures(filters) {
  try {
    const { data } = await natureApi.get("", {
      params: filters,
      paramsSerializer: qs.stringify,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}

export async function getTestTypes(natureId, filters) {
  try {
    const { data } = await natureApi.get(`/${natureId}/test-types`, {
      params: filters,
      paramsSerializer: qs.stringify,
    });

    return data;
  } catch (error) {
    throw erros.INTERNAL_SERVER_ERROR;
  }
}
